

InspectionForm = {
	
	p_image_max : 3
	,
	init_ui : function(){
		
		InspectionForm.setup_add_images();
		InspectionForm.setup_remove_images();
		
	}
	,
	setup_add_images : function(){
		
		// Add image links.
		jQuery('[data-class="add-image"]').each(function(){
			
			var link = jQuery(this);
			
			link.click(function(event){
				
				// TODO: Check for max count
				
				var image_set = link.closest('.images');
				var image_html = image_set.find('.image').first();
				
				console.log(image_set,image_html);
				
				image_html.clone().val('').insertBefore(image_set.children('.controls'));
				
				InspectionForm.setup_remove_images();
				
				// TODO: Update max count
				
				// Stop propagation
				event.preventDefault();
				
			});
		});
		
	}
	,
	setup_remove_images : function(){
		
		// Remove image links.
		jQuery('[data-class="remove-image"]').each(function(){
			
			var link = jQuery(this);
			
			link.click(function(event){
				
				var parent = link.closest('.image');
				
				jQuery(parent).remove();
				
				// TODO: Update max count
				
				// Stop propagation
				event.preventDefault();
				
			});
		});
		
	}
	
};


function scrollToAnchor(aid){
	var aTag = jQuery(aid);//$("a[name='"+ aid +"']");
	jQuery('html,body').animate({scrollTop: aTag.offset().top},'slow');
}

function validate_forms()
{
	// Fetch all the forms we want to apply custom Bootstrap validation styles to
    //var forms = jQuery('.needs-validation');
    var forms = document.getElementsByClassName('needs-validation');
    
    // Loop over them and prevent submission
    var validation = Array.prototype.filter.call(forms, function(form) {
    	form.addEventListener('submit', function(event) {
			if (form.checkValidity() === false) {
				event.preventDefault();
				event.stopPropagation();
    		}
			form.classList.add('was-validated');
    	}, false);
    });
}


jQuery(document).ready(function(jQuery){
	
	// Add scrollTo links
	jQuery('.scroll-to').each(function(){
		
		var link = jQuery(this);
		var href = link.attr('href');
		
		link.click(function(){
			// Scroll to the link
			scrollToAnchor(href);
			
			// TODO: Highlight the destination
		});
		
		console.log(link.attr('href'));
	});
	
	InspectionForm.init_ui();
	
	//validate_forms();
	
	jQuery('#rooms .collapse').on('shown.bs.collapse', function () {
		var id = jQuery(this).siblings('.card-header').attr('id');
		console.log('SHOW!!!',id);
		scrollToAnchor('#' + id);
	})
	
	console.log('Load Inspection Script');
	
});
